
import {
    defineComponent, reactive, ref
} from 'vue';
import List from '@/components/common/list';
import { alarmRecordsApi, pmCommonApi } from '@/api';
import ExportLogDialog from '@/views/pm/alarm-records/components/export-log-dialog.vue';
import { community } from '@/data';
import moment from 'moment';

const column = [
    {
        name: 'AlarmTime',
        label: WordList.AlarmTime,
        type: 'customize'
    }, {
        name: 'Building',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'Device',
        label: WordList.TabelUpdateBoxDevice
    }, {
        name: 'Type',
        label: WordList.TabelKeyInHtmlType
    }];
export default defineComponent({
    components: {
        ExportLogDialog,
        List
    },
    setup() {
        const formData = reactive({
            BeginTime: '',
            EndTime: '',
            row: '10',
            page: '1'
        });
        const tableData = reactive({
            total: 0,
            row: [],
            detail: []
        });

        const isShowExport = ref(false);

        const updateDate = ref(0);

        const getRecordsApi = () => {
            pmCommonApi.getUnReadLogNum((alert: {
                Number: number;
            }) => {
                community.alertCount.value = Number(alert.Number);
                const data = { ...formData };
                data.BeginTime = moment(data.BeginTime).format('YYYY-MM-DD HH:mm:ss');
                data.EndTime = moment(data.BeginTime).format('YYYY-MM-DD HH:mm:ss');

                alarmRecordsApi.getLogList(formData, (res: {
                    total: number;
                    row: never[];
                    detail: never[];
                }) => {
                    tableData.total = res.total;
                    tableData.row = res.row;
                    tableData.detail = res.detail;
                    updateDate.value += 1;
                });
            });
        };

        getRecordsApi();

        return {
            column,
            formData,
            getRecordsApi,
            tableData,
            isShowExport,
            updateDate
        };
    }
});
